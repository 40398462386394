import React from 'react';

// import brands data
import { brands } from '../data';

const Brands = () => {
  return (
    <div className="bg-tertiary">
      <section className='min-h-[146px] bg-tertiary flex items-center'>
        <div
          className='container mx-auto flex items-center flex-wrap justify-evenly'
        >
          {brands.map((brand, idx) => {
            return (
              <div
                key={idx}
              >
                {brand.href !== undefined || brand.href === '' ?
                  <a target="_blank" href={brand.href}><img src={brand.img} alt='' /></a>
                  : <img src={brand.img} alt='' /> }
              </div>
            );
          })}
        </div>
      </section>
    </div>
  );
};

export default Brands;
