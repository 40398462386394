import React from 'react';

// import components
import Projects from './Projects';

const Portfolio = () => {
  return (
    <section id='portfolio' className='section bg-primary min-h-[140px]'>
      <div className='container mx-auto'>
        <div className='flex flex-col items-center text-center'>
          <h2 className='section-title before:content-portfolio relative before:absolute before:opacity-40 before:-top-[2rem] before:-left-3/4 before:hidden before:lg:block'>
            Our latest work
          </h2>
          <p className='subtitle'>
            We help organizations, in a responsible way, transform their business. 
            We achieve this by thinking together and implementing transformation programs 
            tailored to their business objectives. We design and operate transformation programs 
            for your organization, tailored to your identity and changing business environment.
          </p>
        </div>
        <Projects />
      </div>
    </section>
  );
};

export default Portfolio;
