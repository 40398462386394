import React from 'react';
import { Link } from 'react-scroll';


// import img
// import Image from '../assets/img/about.webp';
import Image from '../assets/img/banner1.png';



const About = () => {
  return (
    <section className='section bg-secondary' id='about'>
      <div className='container mx-auto'>
        <div className='flex flex-col xl:flex-row gap-24'>
          <img
            className='object-cover h-full w-[566px] md:mx-auto lg:mx-0 rounded-2xl'
            src={Image}
            alt=''
          />
          <div className='flex flex-col items-center text-center lg:items-start lg:text-left'>
            <div className='flex flex-col'>
              <h2 className='text-3xl lg:text-4xl font-medium lg:font-extrabold mb-3 before:content-about relative before:absolute before:opacity-40 before:-top-[2rem] before:hidden before:lg:block'>
                About Us
              </h2>
              <p className='mb-4 text-accent'>
                Software Development Company
              </p>
              <hr className='mb-8 opacity-5' />
              <p className='mb-8'>
              We believe in change that enables organizations to serve people better and become stronger in the long run. 
              By tailoring transformation programs, we adapt their digital business processes to make real impact. 
              <br/>
              <br/>

              Our developers have various skill sets and experience levels, but they share the same Graosoft spirit. 
              We look at everything we do from a human perspective because business is done among people, also in this digital age.
              Besides helping out organizations, we like to join forces with them in giving something back to society. <br />

              </p>
            </div>
            <Link
              to='contact'
              activeClass='active'
              spy={true}
              smooth={true}
              duration={500}
              offset={-70}
              className='transition-all duration-300'
            >
              <button className='btn btn-md bg-accent hover:bg-secondary-hover md:btn-lg transition-all'>
                Contact us!
              </button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
