import React from 'react';
import { Link } from 'react-scroll';


// import woman image
import WomanImg from '../assets/img/banner-woman2.webp';
// import Banner from '../assets/img/banner.png'
// import Banner from '../assets/img/banner1.png'
// import Banner from '../assets/img/banner2.gif'
// import Banner from '../assets/img/banner3.gif'
// import Banner from '../assets/img/banner4.gif'
import Banner from '../assets/img/banner5.gif'

const Hero = () => {
  return (
    <section
      id='home'
      className='lg:h-[85vh] flex items-center bg-primary lg:bg-cover lg:bg-center lg:bg-no-repeat py-32 lg:py-0 overflow-hidden'
    >
      <div className='container mx-auto h-full'>
        <div className='flex items-center h-full pt-8'>
          <div className='flex-1 flex flex-col items-center lg:items-start'>
            {/* <p className='text-lg text-accent text-md mb-[22px]'>
              Hey, We are the Graosoft team! 👋
            </p> */}
            <h1 className='text-4xl leading-[44px] md:text-5xl md:leading-tight lg:text-7xl lg:leading-[1.2] font-bold md:tracking-[-2px]'>
              We care about our customers.
            </h1>
            <p className='pt-4 pb-8 md:pt-6 md:pb-12 max-w-[480px] text-lg text-center lg:text-left'>
              Graosoft is a software company offering services in the field of mobile and website development.
            </p>
            <Link
              to='contact'
              activeClass='active'
              spy={true}
              smooth={true}
              duration={500}
              offset={-70}
              className='transition-all duration-300'
            >
              <button className='btn btn-md bg-accent hover:bg-secondary-hover md:btn-lg transition-all'>
                Work with us!
              </button>
            </Link>
          </div>
          <div className='hidden lg:flex flex-1 justify-end items-end '>
            <img src={Banner} alt='' />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
