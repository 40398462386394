// export default Team;
import React, { useState, useEffect } from 'react';

// import data
import { teamData } from '../data';
import { teamNav } from '../data';

// import components
import Person from './Person';

const Team = () => {
    const [item, setItem] = useState({ name: 'all' });
    const [person, setPerson] = useState([]);
    const [active, setActive] = useState(0);

    useEffect(() => {
        // get persons based on item
        if (item.name === 'all') {
            setPerson(teamData);
        } else {
            const newTeam = teamData.filter((person) => {
                return person.category.toLowerCase() === item.name;
            });
            setPerson(newTeam);
        }
    }, [item]);

    const handleClick = (e, index) => {
        setItem({ name: e.target.textContent.toLowerCase() });
        setActive(index);
    };

    return (
        <div>
            <section id='team' className='section bg-tertiary min-h-[140px]'>
                <div className='container mx-auto'>
                    <div className='flex flex-col items-center text-center'>
                        <h2 className='section-title before:content-about relative before:absolute before:opacity-40 before:-top-[2rem] before:-left-3/4 before:hidden before:lg:block'>
                            Our Team
                        </h2>
                        <p className='subtitle'>
                        We believe in change that enables organizations to serve people better and become stronger in the long run. By tailoring transformation programs, we adapt their digital business processes to make real impact. 
                        </p>
                    </div>
                    {/* persons nav */}
                    <nav className='mb-12 max-w-xl mx-auto'>
                        <ul className='flex flex-col md:flex-row justify-evenly items-center text-white'>
                            {teamNav.map((item, index) => {
                                return (
                                    <li
                                        onClick={(e) => {
                                            handleClick(e, index);
                                        }}
                                        className={`${active === index ? 'active' : ''
                                            } cursor-pointer capitalize m-4`}
                                        key={index}
                                    >
                                        {item.name}
                                    </li>
                                );
                            })}
                        </ul>
                    </nav>
                    {/* persons */}
                    <section className='grid gap-y-12 lg:grid-cols-3 lg:gap-x-8 lg:gap-y-8'>
                        {person.map((item) => {
                            return <Person item={item} key={item.id} />;
                        })}
                    </section>
                </div>
            </section>
        </div>
    );
};

export default Team;
