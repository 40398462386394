//  icons
import {
  FiLinkedin,
  FiInstagram,
  FiGithub,
  FiLayout,
  FiSettings,
  FiPenTool,
  FiFacebook,
  FiSmartphone,
  FiTag,
  FiMail,
  FiMapPin,
  FiMonitor,
  FiUser
} from 'react-icons/fi';

// companies icons
import FreelancerBrandIcon from './assets/img/brands/client-1.png';
import UpworkBrandIcon from './assets/img/brands/client-2.png';
import FiverBrandIcon from './assets/img/brands/client-3.png';
import BehanceBrandIcon from './assets/img/brands/client-4.png';
import DribbbleBrandIcon from './assets/img/brands/dribbble.png';

// projects images
import Project1 from './assets/img/projects/project1.png';
import Project2 from './assets/img/projects/project2.png';
import Project3 from './assets/img/projects/project3.png';
import Project4 from './assets/img/projects/project4.png';
import Project5 from './assets/img/projects/project5.png';
import Project6 from './assets/img/projects/project6.png';
import Project7 from './assets/img/projects/project7.png';
import Project8 from './assets/img/projects/project6.png';
import Project9 from './assets/img/projects/project6.png';


// projects images
import Person1 from './assets/img/team/ddimchev.jpg';
import Person2 from './assets/img/team/vnikolov.jpg';
import Person3 from './assets/img/team/amilchov.png';
import Person4 from './assets/img/team/sasenov.png';
import Person5 from './assets/img/team/mvelkov.jpg';
import Person6 from './assets/img/team/kvelinov.jpg';

// skills images
import SkillPhp from './assets/img/skills/php.png';
import SkillPython from './assets/img/skills/python.png';
import SkillTypescript from './assets/img/skills/typescript.png';
import SkillJs from './assets/img/skills/js.png';
import SkillReact from './assets/img/skills/reactjs.png';
import SkillAndroid from './assets/img/skills/android.png';
import SkillNodejs from './assets/img/skills/nodejs.png';
import SkillGit from './assets/img/skills/git.png';
import SkillIos from './assets/img/skills/ios.png';
import SkillVue from './assets/img/skills/vuejs.png';

// testimonial images
import TestiImage1 from './assets/img/testimonials/testimonial-1.webp';
import TestiImage2 from './assets/img/testimonials/testimonial-2.webp';
import TestiImage3 from './assets/img/testimonials/testimonial-3.webp';
import Team from './components/Team';

// navigation
export const navigation = [
  {
    name: 'home',
    href: 'home',
  },
  {
    name: 'about',
    href: 'about',
  },
  {
    name: 'team',
    href: 'team',
  },
  {
    name: 'portfolio',
    href: 'portfolio',
  },
  {
    name: 'services',
    href: 'services',
  },
  // {
  //   name: 'testimonials',
  //   href: 'testimonials',
  // },
  {
    name: 'contact',
    href: 'contact',
  },
];

// social
export const social = [
  {
    icon: <FiLinkedin />,
    href: 'https://www.linkedin.com/company/81330441',
  },
  {
    icon: <FiInstagram />,
    href: 'https://www.instagram.com/graosoft/',
  },
  {
    icon: <FiFacebook />,
    href: 'https://www.facebook.com/graosoft',
  },
  // {
  //   icon: <FiGithub />,
  //   href: '',
  // },
];

// companies
export const brands = [
  {
    img: FreelancerBrandIcon,
    href: 'https://bumaga.bg/',
  },
  // {
  //   img: UpworkBrandIcon,
  //   href: 'https://ardes.bg/magazini-i-servizi/pernik/pernik',
  // },
  {
    img: BehanceBrandIcon,
    href: 'https://cscd-bg.org',
  },
  {
    img: FiverBrandIcon,
  },

];

export const teamData = [
  {
    id: '1',
    image: Person2,
    name: 'Veselin Nikolov',
    category: 'Managment',
    description: 'Technology Manager'
  },
  {
    id: '2',
    image: Person1,
    name: 'Denislav Dimchev',
    category: 'Managment',
    description: 'Chief Executive Officer '
  },
  {
    id: '3',
    image: Person3,
    name: 'Aleks Milchov',
    category: 'Managment',
    description: 'Sales & Marketing Manager'
  },
  {
    id: '4',
    image: Person6,
    name: 'Kaloyan Velinov',
    category: 'Consulting',
    description: 'Business Consultant'
  },
  {
    id: '5',
    image: Person4,
    name: 'Slavi Asenov',
    category: 'Development',
    description: 'Software Developer'
  },
  {
    id: '6',
    image: Person5,
    name: 'Martin Velkov',
    category: 'Development',
    description: 'Software Developer'
  },

]

// projects
export const teamNav = [
  {
    name: 'all',
  },
  {
    name: 'Managment'
  },
  {
    name: 'Development',
  },
  {
    name: 'Consulting',
  },
  // {
  //   name: 'Digital Marketing',
  // },
];

// projects
export const projectsData = [
  {
    id: '1',
    image: Project1,
    name: 'JustAround',
    category: 'Mobile Development',
    description: '✅ Completed ✅'
  },
  {
    id: '2',
    image: Project2,
    name: 'TOMI - PLAST',
    category: 'Web development',
    description: 'In Progress... ⌛'
  },
  {
    id: '3',
    image: Project3,
    name: 'Metasport',
    category: 'Desktop Development',
    description: '✅ Completed ✅'
  },
  {
    id: '4',
    image: Project4,
    name: 'Terran Command Center',
    category: 'Web Development',
    description: '✅ Completed ✅'
  },
  {
    id: '5',
    image: Project5,
    name: 'ChildStatus',
    category: 'Mobile Development',
    description: 'In Progress... ⌛'
  },
  {
    id: '6',
    image: Project6,
    name: 'Terran Mobile',
    category: 'Mobile Development',
    description: '✅ Completed ✅'
  },
  {
    id: '7',
    image: Project7,
    name: 'Technocommerce',
    category: 'Mobile Development',
    description: 'In Progress... ⌛'
  },
];

// projects
export const projectsNav = [
  {
    name: 'all',
  },
  {
    name: 'Web development',
  },
  {
    name: 'Mobile development',
  },
  {
    name: 'Desktop development',
  },
];

// skill
// import SkillJs from './assets/img/skills/js.png';
// import SkillReact from './assets/img/skills/reactjs.png';
// import SkillAndroid from './assets/img/skills/android.png';
// import SkillNodejs from './assets/img/skills/nodejs.png';
// import SkillGit from './assets/img/skills/git.png';
// import SkillFigma from './assets/img/skills/figma.png';
export const skills = [
  {
    image: SkillReact,
  },
  {
    image: SkillTypescript,
  },
  {
    image: SkillJs,
  },
  {
    image: SkillNodejs,
  },
  {
    image: SkillAndroid,
  },
  {
    image: SkillIos,
  },
  {
    image: SkillPhp,
  },
  // {
  //   image: SkillWordpress,
  // },
  // {
  //   image: SkillGit,
// }
  {
    image: SkillVue,
  },

];

// services
export const services = [
  {
    icon: <FiSmartphone />,
    name: 'Mobile Development',
    description:
      'With in-depth knowledge of native iOS, Android and cross-platform application development, we make sure that your idea is transformed into a beautifully designed, user-friendly and engaging mobile solution of a superior quality.',
  },
  {
    icon: <FiMonitor />,
    name: 'Web Development',
    description:
      'Enterprise systems, apps, intranets and more - we take an agile approach to building scalable custom software, integrating the latest technologies, with a dynamic user-friendly experience.',
  },
  {
    icon: <FiSettings />,
    name: 'ERP',
    description:
      'ERP (Enterprise resource planning) software is a set of computer systems that support and facilitate the core work processes of a company.',
  },
  {
    icon: <FiUser />,
    name: 'CRM',
    description:
      'Customer Relationship Management systems (or customer relationship management systems) are application software that help organizations and businesses manage interactions with their customers.',
  },
];

// testimonials
export const testimonials = [
  {
    authorImg: TestiImage1,
    authorText:
      'Love the simplicity of the service and the prompt customer support. We can’t imagine working without it.',
    authorName: 'Olivia Doe',
    authorPosition: 'Head of Design, Google',
  },
  {
    authorImg: TestiImage2,
    authorText:
      'Love the simplicity of the service and the prompt customer support. We can’t imagine working without it.',
    authorName: 'Olivia Doe',
    authorPosition: 'Head of Design, Google',
  },
  {
    authorImg: TestiImage3,
    authorText:
      'Love the simplicity of the service and the prompt customer support. We can’t imagine working without it.',
    authorName: 'Olivia Doe',
    authorPosition: 'Head of Design, Google',
  },
];

// contact
export const contact = [
  {
    icon: <FiMail />,
    title: 'Have a question?',
    subtitle: 'We are here to help you.',
    description: 'Email us at office@graosoft.com',
  },
  {
    icon: <FiMapPin />,
    title: 'Current Location',
    subtitle: 'Pernik, Bulgaria',
    description: 'Serving clients worldwide',
  },
];
